import React, { Component, Link } from "react";

const Navbar = (props) => {
  return (
    <div className={props.classes}>
      <h2 className="navItem" onClick={props.toggleAbout}>
        about
      </h2>
      <h2 className="navItem" onClick={props.toggleMedia}>
        gallery
      </h2>
      <h2 className="navItem" onClick={props.toggleSchedule}>
        schedule
      </h2>
      <h2 className="navItem" onClick={props.toggleMerch}>
        merch
      </h2>
      <h2 className="navItem" onClick={props.toggleContact}>
        contact
      </h2>
    </div>
  );
};

export default Navbar;
