import React, { Component } from "react";
import logoBone from "../pics/WTLogoBoneNoShadow.png";
import shadow from "../pics/shadow.png";

const MiddleSection = (props) => {
  return (
    <div className="middleSectionContainer">
      <div>
        <img
          src={logoBone}
          alt="Logo Here"
          className="floatingHand animate__animated animate__slideInDown"
        />
        <img
          src={shadow}
          alt=""
          className="shadow animate__animated animate__zoomIn"
        />
      </div>
    </div>
  );
};

export default MiddleSection;
