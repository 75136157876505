import React, { Component, Link } from "react";
// import Slider from "react-slick";
// import GalCar from "./Carousel";
// import { Carousel } from "react-responsive-carousel";
// import ImageGallery from "react-image-gallery";
import { IoMdArrowDropleft } from "react-icons/io";
import { IoMdArrowDropright } from "react-icons/io";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const Media = (props) => {
  const gallery = props.galList.map((pic) => (
    <Slide>
      <img src={pic} className="galpic" />
    </Slide>
  ));
  return (
    <div className={props.classes}>
      <div className="tabExit" onClick={props.toggleMedia}>
        <div className="exit1"></div>
        <div className="exit2"></div>
      </div>
      <h1>gallery</h1>
      <div className="mediaContainer">
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={9}
          infinite={true}
        >
          <DotGroup className="dotgroup" />
          <Slider>{gallery}</Slider>
          <ButtonBack className="backBtn">
            <IoMdArrowDropleft />
          </ButtonBack>
          <ButtonNext className="nextBtn">
            <IoMdArrowDropright />
          </ButtonNext>
        </CarouselProvider>
      </div>
    </div>
  );
};

export default Media;

// const Media = (props) => {
//   const gallery = props.galList.map((pic) => (
//     <div>
//       <img src={pic} className="galpic" />
//     </div>
//   ));

//   let settings = {
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     dots: true,
//   };

//   return (
//     <div className={props.classes}>
//       <div className="tabExit" onClick={props.toggleMedia}>
//         <div className="exit1"></div>
//         <div className="exit2"></div>
//       </div>
//       <h1>gallery</h1>
//       <div className="mediaContainer">
//         <Slider {...settings}>{gallery}</Slider>
//       </div>
//     </div>
//   );
// };

// export default Media;

// const Media = (props) => {
//   const gallery = props.galList.map((pic) => (
//     <div>
//       <img src={pic} className="galpic" />
//       <p className="legend">legend</p>
//     </div>
//   ));

//   return (
//     <div className={props.classes}>
//       <div className="tabExit" onClick={props.toggleMedia}>
//         <div className="exit1"></div>
//         <div className="exit2"></div>
//       </div>
//       <h1>gallery</h1>
//       <div className="mediaContainer">
//         <Carousel showArrows={true}>{gallery}</Carousel>
//       </div>
//     </div>
//   );
// };

// export default Media;
