import React, { Component, Link } from "react";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineYoutube,
} from "react-icons/ai";

const Contact = (props) => {
  return (
    <div className={props.classes}>
      <div className="tabExit" onClick={props.toggleContact}>
        <div className="exit1"></div>
        <div className="exit2"></div>
      </div>
      <h1>contact</h1>
      <div className="contact-wrapper">
        <center>
          <p>
            Email us at <br />
            <span className="emailContact">wolfticketsband@gmail.com</span>
            <br />
            or
            <br />
            <span className="messageContact">
              message us on Facebook or Instagram
            </span>
          </p>

          <a href="https://www.facebook.com/wolfticketsband" target="_blank">
            <AiOutlineFacebook size="2.3em" className="fbIcon" />
          </a>
          <a href="https://www.instagram.com/wolfticketsband/" target="_blank">
            <AiOutlineInstagram size="2.4em" className="instaIcon" />
          </a>
        </center>
        {/* <label for="fname">Name</label>
        <input type="text" id="fname" name="fname" />
        <br />
        <label for="lname">Email</label>
        <input type="email" id="lname" name="lname" />
        <br />
        <label for="msg">Message</label> <br />
        <textarea rows={6} type="text" id="msg" name="msg" />
        <button type="submit">Send It!</button> */}
      </div>
    </div>
  );
};

export default Contact;
