import React, { Component, Link } from "react";

const MenuUI = (props) => {
  return (
    <div className="menuContainer">
      <div className={props.classes} onClick={props.toggleMenu}>
        <div className="menuTop"></div>
        <div className="menuMid"></div>
        <div className="menuBot"></div>
      </div>
    </div>
  );
};

export default MenuUI;
