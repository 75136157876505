import React, { Component, Link } from "react";
import ScheduleCalendar from "./Calendar";
import { AiTwotoneCalendar } from "react-icons/ai";
import { TiLocationArrow } from "react-icons/ti";
import { BiTimeFive } from "react-icons/bi";
import { HiOutlineLocationMarker } from "react-icons/hi";

const Schedule = (props) => {
  const calEvents = props.eventList.map((event) => (
    <li className="grid-item">
      <span className="schedulespan">
        <AiTwotoneCalendar className="icon" /> {event[1]}
      </span>
      <span className="schedulespan">
        <HiOutlineLocationMarker className="icon" />
        {event[2]}
      </span>
      <span className="schedulespan">
        <BiTimeFive className="icon" /> {event[3]}
      </span>
      <span className="schedulespan">
        <TiLocationArrow className="icon" />
        <a href={event[4]} target="_blank">
          Get Directions
        </a>
      </span>
    </li>
  ));

  return (
    <div className={props.classes}>
      <div className="tabExit" onClick={props.toggleSchedule}>
        <div className="exit1"></div>
        <div className="exit2"></div>
      </div>
      <h1>schedule</h1>
      <ScheduleCalendar onChange={props.onChange} date={props.date} />
      <h4>Upcoming Shows</h4>
      <ul className="showsList grid-container">{calEvents}</ul>
    </div>
  );
};

export default Schedule;
