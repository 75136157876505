import React, { Component } from "react";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineYoutube,
} from "react-icons/ai";

const Socials = (props) => {
  return (
    <div className="socials animate__animated animate__slideInUp animate__fadeIn">
      <center>
        <a href="https://www.facebook.com/wolfticketsband" target="_blank">
          <AiOutlineFacebook size="2.5em" className="fbIcon" />
        </a>
        <a href="https://www.instagram.com/wolfticketsband/" target="_blank">
          <AiOutlineInstagram size="2.5em" className="instaIcon" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCEFFp8UyuKDX-RJycnfl2uQ/featured?view_as=subscriber"
          target="_blank"
        >
          <AiOutlineYoutube size="2.5em" className="ytIcon" />
        </a>
      </center>
    </div>
  );
};

export default Socials;
