import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Home from "./Components/Home";
// import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return <Home />;
}

export default App;
