import React, { Component } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const ScheduleCalendar = (props) => {
  return (
    <div>
      <Calendar
        className="schedCal"
        calendarType="US"
        onChange={props.onChange}
        value={props.date}
        tileClassName="tileCal"
        maxDetail="month"
        minDetail="month"
      />
    </div>
  );
};

export default ScheduleCalendar;
