import React, { Component } from "react";
import Navbar from "./Navbar";
import MenuUI from "./MenuUI";
import MiddleSection from "./MiddleSection";
import Socials from "./Socials";
import About from "./About";
import Media from "./Media";
import Schedule from "./Schedule";
import Merch from "./Merch";
import Contact from "./Contact";

import pic1 from "../pics/galPic1.JPG";
import pic2 from "../pics/galPic2.JPG";
import pic3 from "../pics/galPic3.JPG";
import pic4 from "../pics/galPic4.JPG";
import pic5 from "../pics/galPic5.JPG";
import pic6 from "../pics/galPic6.JPG";
import pic7 from "../pics/Connors1.jpg";
import pic8 from "../pics/dylanConnors.png";
import pic9 from "../pics/UVshow.jpg";

class Home extends Component {
  state = {
    menuClassToggle: "menuBtn animate__animated animate__slideInRight",
    navClassToggle: "navbar",
    navItemClassToggle: "navItem",
    aboutClasses: "tab about",
    mediaClasses: "tab media",
    scheduleClasses: "tab schedule",
    merchClasses: "tab merch",
    contactClasses: "tab contact",
    date: new Date(),
    gallery: [pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8, pic9],
    shows: [
      [
        1,
        "Sat Nov 13, 2021",
        "White River Yacht Club",
        "7:30pm",
        "https://goo.gl/maps/bTkFd6HvwTCCyMF6A",
      ],
      // [
      //   1,
      //   "Sat Dec 12, 2020",
      //   "Rathskeller",
      //   "8pm",
      //   "https://www.google.com/maps/place/The+Rathskeller/@39.7737737,-86.1524915,17z/data=!3m1!4b1!4m5!3m4!1s0x886b50eb5466cbbb:0xe967edd05b1f7131!8m2!3d39.7737737!4d-86.1503028",
      // ],
    ],
  };

  // componentDidMount() {}

  onScheduleChange = (date) => this.setState({ date });
  // formatLongDate = (locale, date) => formatDate(date, "dd MMM YYYY");

  handleMenuClick = () => {
    if (
      this.state.menuClassToggle ===
      "menuBtn animate__animated animate__slideInRight"
    ) {
      this.setState({
        menuClassToggle:
          "menuBtn animate__animated animate__slideInRight activeMenuBtn",
      });
      this.setState({ navClassToggle: "navbar activeNav" });
      // this.setState({ navItemClassToggle: "navItem activeNavItem" });
      console.log(this.state.menuClassToggle);
    } else {
      this.setState({
        menuClassToggle: "menuBtn animate__animated animate__slideInRight",
      });
      this.setState({ navClassToggle: "navbar" });
      // this.setState({ navItemClassToggle: "navItem" });
      console.log(this.state.menuClassToggle);
    }
  };

  handleAboutClick = () => {
    if (this.state.aboutClasses === "tab about") {
      this.setState({ aboutClasses: "tab about activeAbout" });
      this.setState({ mediaClasses: "tab media" });
      this.setState({ scheduleClasses: "tab schedule" });
      this.setState({ merchClasses: "tab merch" });
      this.setState({ contactClasses: "tab contact" });
      console.log(this.state.menuClassToggle);
    } else {
      this.setState({ aboutClasses: "tab about" });
      console.log(this.state.menuClassToggle);
    }
  };

  handleMediaClick = () => {
    if (this.state.mediaClasses === "tab media") {
      this.setState({ aboutClasses: "tab about" });
      this.setState({ mediaClasses: "tab media activeTab" });
      this.setState({ scheduleClasses: "tab schedule" });
      this.setState({ merchClasses: "tab merch" });
      this.setState({ contactClasses: "tab contact" });
      console.log(this.state.menuClassToggle);
    } else {
      this.setState({ mediaClasses: "tab media" });
      console.log(this.state.menuClassToggle);
    }
  };

  handleScheduleClick = () => {
    if (this.state.scheduleClasses === "tab schedule") {
      this.setState({ aboutClasses: "tab about" });
      this.setState({ mediaClasses: "tab media" });
      this.setState({ scheduleClasses: "tab schedule activeTab" });
      this.setState({ merchClasses: "tab merch" });
      this.setState({ contactClasses: "tab contact" });
      console.log(this.state.menuClassToggle);
    } else {
      this.setState({ scheduleClasses: "tab schedule" });
      console.log(this.state.menuClassToggle);
    }
  };

  handleMerchClick = () => {
    if (this.state.merchClasses === "tab merch") {
      this.setState({ aboutClasses: "tab about" });
      this.setState({ mediaClasses: "tab media" });
      this.setState({ scheduleClasses: "tab schedule" });
      this.setState({ merchClasses: "tab merch activeTab" });
      this.setState({ contactClasses: "tab contact" });
      console.log(this.state.menuClassToggle);
    } else {
      this.setState({ merchClasses: "tab merch" });
      console.log(this.state.menuClassToggle);
    }
  };

  handleContactClick = () => {
    if (this.state.contactClasses === "tab contact") {
      this.setState({ aboutClasses: "tab about" });
      this.setState({ mediaClasses: "tab media" });
      this.setState({ scheduleClasses: "tab schedule" });
      this.setState({ merchClasses: "tab merch" });
      this.setState({ contactClasses: "tab contact activeTab" });
      console.log(this.state.menuClassToggle);
    } else {
      this.setState({ contactClasses: "tab contact" });
      console.log(this.state.menuClassToggle);
    }
  };

  render() {
    return (
      <div className="htmlWrapper">
        <div className="invisdiv"></div>
        <MenuUI
          classes={this.state.menuClassToggle}
          toggleMenu={this.handleMenuClick}
        />
        <Navbar
          classes={this.state.navClassToggle}
          toggleAbout={this.handleAboutClick}
          toggleMedia={this.handleMediaClick}
          toggleSchedule={this.handleScheduleClick}
          toggleMerch={this.handleMerchClick}
          toggleContact={this.handleContactClick}
        />
        <h1 className="topLeftText animate__animated animate__slideInLeft">
          Wolftickets
        </h1>
        <div className="mainOpenerDiv">
          <MiddleSection />
        </div>
        <Socials />
        <About
          classes={this.state.aboutClasses}
          toggleAbout={this.handleAboutClick}
        />
        <Media
          classes={this.state.mediaClasses}
          toggleMedia={this.handleMediaClick}
          galList={this.state.gallery}
        />
        <Schedule
          classes={this.state.scheduleClasses}
          toggleSchedule={this.handleScheduleClick}
          onChange={this.onScheduleChange}
          date={this.state.date}
          selectedDate={this.state.date.toString()}
          eventList={this.state.shows}
          // selectedDate={this.state.date.toString()}
        />
        <Merch
          classes={this.state.merchClasses}
          toggleMerch={this.handleMerchClick}
        />
        <Contact
          classes={this.state.contactClasses}
          toggleContact={this.handleContactClick}
        />
      </div>
    );
  }
}

export default Home;
