import React, { Component, Link } from "react";
import nickPic from "../pics/Nick2.JPG";
import gregPic from "../pics/Greg2.JPG";
import dylanPic from "../pics/Dylan2.JPG";
import tedPic from "../pics/Ted2.JPG";
import dylan from "../pics/DylanFace.png";
import greg from "../pics/GregFace.png";
import nick from "../pics/NickFace.png";
import ted from "../pics/TedFace.png";

const About = (props) => {
  return (
    <div className={props.classes}>
      <div className="tabExit" onClick={props.toggleAbout}>
        <div className="exit1"></div>
        <div className="exit2"></div>
      </div>
      <h1>about</h1>
      <div className="memberContainer">
        <div className="grid-item member1">
          <div className="submember">
            <h3>WOLFTICKETS</h3>
            <img className="mainPic" src={gregPic} alt="" />
            <figcaption>GREG GOODIN</figcaption>
            <div className="memberSpot">
              <h3>VOCALS</h3>
            </div>
          </div>
          <div className="submember memberBG">
            <div className="header">
              <h2>
                GREG "PRETTY BOY" <br /> <span>GOODIN</span>
              </h2>
              <img className="facePic" src={greg} alt="" />
            </div>
            <div className="bio">
              <p>
                After his daily walk on the Indiana beaches, Greg likes to cool
                down with a large mimosa and a 15oz ribeye. When he's not
                practicing his vocal runs, you can catch him doing burpees to
                stay fit for your viewing pleasure.
              </p>
            </div>
          </div>
        </div>
        <div className="grid-item member2">
          <div className="submember">
            <h3>WOLFTICKETS</h3>
            <img className="mainPic" src={dylanPic} alt="" />
            <figcaption>DYLAN ZAVALETA</figcaption>
            <div className="memberSpot">
              <h3>GUITAR</h3>
            </div>
          </div>
          <div className="submember memberBG">
            <div className="header">
              <h2>
                DYLAN "FINGERS" <br /> <span>ZAVALETA</span>
              </h2>
              <img className="facePic" src={dylan} alt="" />
            </div>
            <div className="bio">
              <p>
                Ever since Santa brought Dylan a guitar at the age of 8, he has
                strived to master the guitar basics, working on them still to
                this day. In his free time, you can find him at the local
                climbing gym where he likes to escape the pressures of the real
                world.
              </p>
            </div>
          </div>
        </div>
        <div className="grid-item member3">
          <div className="submember">
            <h3>WOLFTICKETS</h3>
            <img className="mainPic" src={nickPic} alt="" />
            <figcaption>NICK SCHROEDER</figcaption>
            <div className="memberSpot">
              <h3>BASS</h3>
            </div>
          </div>
          <div className="submember memberBG">
            <div className="header">
              <h2>
                NICK "BIG D(EAL)" <br /> <span>SCHROEDER</span>
              </h2>
              <img className="facePic" src={nick} alt="" />
            </div>
            <div className="bio">
              <p>
                Even though bass is the most difficult instrument to play, Nick
                makes it look effortless. Almost as effortless as he looks with
                the wind flowing through his mustache while riding his tall bike
                on a crisp fall afternoon.
              </p>
            </div>
          </div>
        </div>
        <div className="grid-item member4">
          <div className="submember">
            <h3>WOLFTICKETS</h3>
            <img className="mainPic" src={tedPic} alt="" />
            <figcaption>TED VILLANYI</figcaption>
            <div className="memberSpot">
              <h3>DRUMS</h3>
            </div>
          </div>
          <div className="submember memberBG">
            <div className="header">
              <h2>
                TED "RED SOLO CUP" <br /> <span>VILLANYI</span>
              </h2>
              <img className="facePic" src={ted} alt="" />
            </div>
            <div className="bio">
              <p>
                After wasting most of his childhood as a video game addict, Ted
                leveled up from Guitar Hero to an actual skill of playing drums.
                Today, when he is not playing music you will find him on the
                water as a pontoon cruising river rat.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
