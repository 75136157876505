import React, { Component, Link } from "react";
import coaster1 from "../pics/coaster1.png";
import coaster2 from "../pics/coaster2.png";

const Merch = (props) => {
  return (
    <div className={props.classes}>
      <div className="tabExit" onClick={props.toggleMerch}>
        <div className="exit1"></div>
        <div className="exit2"></div>
      </div>
      <h1>merch</h1>
      <div className="merchContainer">
        <center>
          <div className="merch1">
            <img className="coaster c1" src={coaster1} alt="" />
            <figcaption>
              Wolf Ticket Coaster <br /> <span>$420.69</span> <br />
              FREE!
            </figcaption>
          </div>
        </center>
        <center>
          <div className="merch2">
            <img className="coaster c2" src={coaster2} alt="" />
            <figcaption>
              Wolf Howl Coaster <br /> <span>$694.20</span> <br />
              FREE!
            </figcaption>
          </div>
        </center>
      </div>
    </div>
  );
};

export default Merch;
